@import "./reset.scss";
@import "~bootstrap/scss/bootstrap";

:root {
  --BMX-primary: #19aa8d;
  --BMX-secondary: #445d75;
  --BMX-error: #d32f2f;
  --BMX-success: #4caf50;
  --BMX-white: #fff;
  --BMX-black: #000000de;
  --BMX-warning: #ffa500;
  --BMX-grey: #ababab;
  --BMX-dark-grey: #757575;
  --BMX-menu-grey: #a7b1c2;
  --BMX-modal-grey: #d9d9d9;
  --BMX-details-grey: #f2f2f2;
  --BMX-container-white: #fafafa;
  --BMX-details--header-font-grey: #6f6f6f;
  --BMX-table-header-bg: #f1f1f1;
  --BMX-table-header: rgba(0, 0, 0, 0.6);
  --BMX-border-color: rgba(0, 0, 0, 0.12);
  --font-main: clamp(1em, 2vw, 1.2em);
  --font-secondary: clamp(0.8em, 2vw, 1em);
  --BMX-blue: #009FFF
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 100%;
}

h1 {
  font-size: clamp(40px, 5vw, 70px);
}

h2 {
  font-size: clamp(30px, 4vw, 50px);
}

h3 {
  font-size: clamp(20px, 3vw, 40px);
}

p {
  font-size: clamp(1em, 2vw, 1.2em);
}

.typography-main {
  font-size: var(--font-main);
}
.typography-secondary {
  font-size: var(--font-secondary);
}
.primary {
  &.text {
    color: var(--BMX-primary) !important;
  }
  &.background {
    background: var(--BMX-primary) !important;
  }
  &[disabled] {
    color: var(--BMX-dark-grey) !important;
  }
}
.secondary {
  &.text {
    color: var(--BMX-secondary) !important;
  }
  &.text-white {
    color: var(--BMX-white) !important;
  }
  &.background {
    background: var(--BMX-secondary) !important;
  }
}
.darkGrey {
  &.text {
    color: var(--BMX-dark-grey) !important;
  }
  &.background {
    background: var(--BMX-dark-grey) !important;
  }
}
.error {
  &.text {
    color: var(--BMX-error) !important;
  }
  &.background {
    background: var(--BMX-error) !important;
  }
}
.success {
  &.text {
    color: var(--BMX-success) !important;
  }
  &.text-white {
    color: var(--BMX-white) !important;
  }
  &.background {
    background: var(--BMX-success) !important;
  }
  &.text-white {
    color: var(--BMX-white) !important;
  }
}
.warning {
  &.text {
    color: var(--BMX-warning) !important;
  }
  &.text-white {
    color: var(--BMX-white) !important;
  }
  &.background {
    background: var(--BMX-warning) !important;
  }
}
.grey {
  &.text {
    color: var(--BMX-grey) !important;
  }
  &.background {
    background: var(--BMX-grey) !important;
  }
}
.dark-grey {
  &.text {
    color: var(--BMX-dark-grey) !important;
  }
  &.background {
    background: var(--BMX-dark-grey) !important;
  }
}
.blue {
  &.text {
    color: var(--BMX-blue) !important;
  }
  &.text-white {
    color: var(--BMX-white) !important;
  }
  &.background {
    background: var(--BMX-blue) !important;
  }
}
.white{
  &.text {
    color: var(--BMX-white) !important;
  }
  &.background {
    background: var(--BMX-white) !important;
  }
}
.button-grey {
  &.text {
    color: var(--BMX-details-grey) !important;
  }
  &.background {
    background: var(--BMX-details-grey) !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:window-inactive {
  background: #cdcdcd;
}
* ::-webkit-scrollbar-thumb {
  background: #445d75 !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
}
* ::-webkit-scrollbar-track {
  background: #ddd;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.MuiLinearProgress-root {
  background-color: var(--BMX-white) !important;
  .MuiLinearProgress-bar {
    background-color: var(--BMX-primary) !important;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  * {
    .col {
      zoom: 0.85;
      transform: translate(0.1rem);
    }
  }
}

.MuiBadge-colorWarning {
  background: var(--BMX-warning) !important;
}
.MuiBadge-colorPrimary {
  background: var(--BMX-primary) !important;
}
.test {}

.MuiAutocomplete-popper{
  z-index: 999999999 !important;
}

.disabled {
  * {
  color: var(--BMX-dark-grey) !important;
  }
}