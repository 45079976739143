.BMXButton {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  color: gray !important;
  height: 30px;
  padding: 0 20px !important;
  background: var(--BMX-details-grey) !important;
  font-size: 0.65rem !important;
  line-height: 0 !important;

  &.Success {
    background: var(--BMX-primary) !important;
    color: var(--BMX-white) !important;
  }

  &.Send {
    background: var(--BMX-error) !important;
    color: var(--BMX-white) !important;
  }

  &.Secondary {
    background: var(--BMX-secondary) !important;
    color: var(--BMX-white) !important;
  }

  &.Disabled {
    background: var(--BMX-modal-grey) !important;
    color: gray !important;
  }

  z-index: 1;
  white-space: nowrap !important;
}

.BMXFab {
  * {
    font-size: 15px !important ;
  }

  &.MuiButtonBase-root {
    &.Success {
      background-color: var(--BMX-primary) !important;
      color: var(--BMX-white) !important;
    }
    &.Secondary {
      background-color: var(--BMX-secondary) !important;
      color: var(--BMX-white) !important;
    }
  }
}