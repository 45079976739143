.FormManagementFormStepOne {
  input {
    font-size: 0.9em;
  }
  .MainRow {
    height: 100%;
  }

  .TableArea {
    border-left: 1px solid var(--BMX-grey);
    .DetailsTable {
      margin: -1.2rem 0 0 1rem;
    }
  }

  [aftericon="search"] {
    .material-icons {
      color: var(--BMX-primary) !important;
    }
  }
}
