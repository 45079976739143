.BMXPortal {
    position: fixed;
    z-index: 99999999;
}

body:has(.BMXPortal) {
    #root,
    .BMXModal {
        filter: blur(1px);
    }

    .BMXPortal{
        .curtain{
            position: fixed;
            transform: translate(-50%,-50%);
            top:50%;
            left: 50%;
            width: 1000dvw;
            height: 1000dvh;
            background: rgba(0,0,0,0.5) !important;
            z-index: -1;
        }
    }
}
