@media print {
    .PrintDocumentButtonsButtonsArea,
    .BMXPortal {
        display: none;
    }
    html, body, .PrintCard {
        width: 21cm;
        height: 29.7cm;
      }
    
}

.PrintCard{
    min-height: 29.6866cm;
    max-width: 21cm;
    padding: 5mm 0;
}

@page {
    size: A4;
    margin: 0;
  }


.PrintDocument {          
    .PrintArea {
        > div {
            background: var(--BMX-white);
            border: solid 0.5px black;
            box-shadow:var(--BMX-black) 0 0 10px 2px;
            width: 210mm;    
            margin: 10mm auto;  
            padding: 1rem;      
      
        }
    }

    .PrintDocumentButtonsButtonsArea {
        position: fixed;
        bottom: 0.5rem;
        right: 0;
        .BMXFab {
            margin-right: 0.5rem;
        }

        button {
            span {
                font-size: 20px;
            }
        }
    }
}
