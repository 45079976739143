.BMXRadioButton{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    .RadioElement{
        align-items: center;
        display: flex;
        span {
            color: var(--BMX-primary);
        }
        span,label {
            cursor: pointer !important;
        }
        
    }
}