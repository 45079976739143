.BMXAutocomplate {
  margin-top: -5.5px;
  margin-bottom: 2rem;
  *::after{
    border-bottom: 2px solid var(--BMX-grey) !important;
  }
  .MuiInputLabel-shrink {
    color: var(--BMX-grey) !important; 
  }
  .InputArea {
    display: flex;
    align-items: flex-end;
    position: relative;
    .MuiFormHelperText-root{
      position: absolute;
      bottom:-22px;
    }
  }
  &.Multiple {
    .MuiInput-root {
      min-height: 44px !important;
    }
  }

  .MuiFormControl-root {
    label {
      margin-top: 4.5px;
      font-size: 0.9em;
    }
    .MuiInputLabel-shrink {
      margin-top: 5px;
    }
  }
}
