.DetailsTable {
    .MuiTableContainer-root {
        max-height: 200px;
        overflow: auto;
        margin-top: 10px;
        margin-right: 50px;
        margin-left: -15px;
        padding-left: 0px;
    }

    .TableDataRow {
        > tr,
        td {
            border: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: default;
        }
    }

    .DetailsTableButtons{
        display: flex;
        justify-content: flex-end;

        .MuiButtonBase-root{
            size: .3rem;
            *{
                font-size: 1rem;     
            }
        }
    }
}
