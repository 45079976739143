.FormLaminFormStepOne {
  position: relative;
  .drawing {
    margin-top: -1.25em;
  }
  .TableArea{
    position: absolute;
    top:0;
    right: 0;
  }
}
