.TargetPrintAreaComplaintTo {
    font-size: 0.9rem;
    color: var(--BMX-black);
    font-weight: 400;
    position: relative;
    .HeaderArea {
        width: 100%;
        padding-left: 2rem;
        display: flex;
        .LogoArea {
            width: 60%;
            .LaminLogo {
                max-height: 4rem;
                margin-bottom: 0.5rem;
            }
            span {
                display: block;
            }
        }

        .DateArea {
            padding-right: 1rem;
            display: flex;
            width: 40%;
            justify-content: flex-end;
            margin: 2.5rem 0;
        }
    }
    .HeaderEnd {
        margin-top: 2rem;
        height: 4rem;
        width: 100%;
        background-color: var(--BMX-warning);
        display: flex;
        justify-content: flex-end;
        .InnerText {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 7rem;
            // padding:;
            height: 100%;
            font-size: large;
            font-weight: 600;
            width: fit-content;
            max-width: 14rem;
            text-align: center;
            box-shadow:
                inset var(--BMX-white) 0 0 0 10px,
                var(--BMX-white) 0 0 0 10px;
            background: var(--BMX-white);
        }
    }
    .ComplaintInfoArea {
        margin-top: 4rem;
        .ReportingComplaints,
        .RecipientOfTheComplaint {
            display: inline-flex;
            flex-direction: column;
            width: 50%;
            padding: 0 1.5rem;
            margin-top: 0 !important;
        }
    }
    .ComplaintList {
        display: flex;
        margin: .5rem;
        .ComplaintItem {
            flex-direction: column;
            display: flex;          
            flex: 1 1;
            .ComplaintHeader,.ComplaintData{
                text-align: center;
                white-space: nowrap;               
            }
            .ComplaintHeader{
                background: var(--BMX-warning);
                color: var(--BMX-white);               
                font-weight: 400;
                padding: 0 1rem;
            }
            .ComplaintData{
                border: 1px solid var(--BMX-grey);
            }
        }
    }
    .SginArea{
        display: flex;
        justify-content: space-around;
        height: 26rem;
        align-items: center;
        .Sign1, .Sign2{ 
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 5rem;
            justify-content: space-between;
        }
    }
    .Footer {
        position: absolute;
        bottom:0;
        background: var(--BMX-warning);
        width: 100%;
        text-align: center;
        color: var(--BMX-white);               
        font-weight: 400;
    }
}
