.FormManagementElementForm {
    position: relative;
    .MuiFormControl-root label {
        margin-top: auto;
    }

    .Success{
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .BottomSeparator{
        position: absolute;
        bottom: 2rem;
    }
}
