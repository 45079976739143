.TargetPrintAreaFromManagement {
    font-size: 0.9rem;
    color: var(--BMX-black);
    font-weight: 400;
    position: relative;
    .HeaderArea {
        width: 100%;
        padding-left: 2rem;
        display: flex;
        .LogoArea {
            width: 60%;
            .LaminLogo {
                max-height: 4rem;
                margin-bottom: 0.5rem;
            }
            span {
                display: block;
            }
        }

        .DateArea {
            padding-right: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 40%;
            margin: 1.5rem 0;
            > span {
                text-align: center;
                &:last-child {
                    margin-top: 1rem;
                }
                > span,
                h5 {
                    display: block;
                    margin-right: 1rem;
                }
            }
        }
    }
    .HeaderEnd {
        margin-top: 2rem;
        height: 4rem;
        width: 100%;
        background-color: var(--BMX-warning);
        display: flex;
        justify-content: flex-end;
        .InnerText {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 7rem;
            // padding:;
            height: 100%;
            font-size: large;
            font-weight: 600;
            width: fit-content;
            max-width: 14rem;
            text-align: center;
            box-shadow:
                inset var(--BMX-white) 0 0 0 10px,
                var(--BMX-white) 0 0 0 10px;
            background: var(--BMX-white);
        }
    }
    .FormManagementInfoArea {
        margin-top: 4rem;
        margin-right: 2rem;
        margin-left: 2rem;
        min-height: 13rem;

        > div {
            display: inline-flex;
            flex-direction: column;
            width: 50%;
            > span {
                display: block;
                font-weight: 600;
            }
            > div {
                margin-bottom: 1rem;
                > span {
                    display: block;
                    &:nth-child(1) {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .FormManagementList {
        display: flex;
        margin: 0.5rem;
        min-height: 17rem;
        flex-direction: column;
        .FormManagementRow {
            display: flex;
        }
        .FormManagementItem {
            flex: 1 1;
            .FormManagementHeader,
            .FormManagementData {
                text-align: start;
                padding-left: 0.3rem;
                white-space: nowrap;
                height:100%
            }
            .FormManagementHeader {
                background: var(--BMX-warning);
                color: var(--BMX-white);
                font-weight: 400;
                padding: 0 1rem;
            }
            .FormManagementData {
                border: 1px solid var(--BMX-grey);
                min-height: 23.61px;
                word-wrap: break-word;
                text-wrap: wrap;
            }
        }
    }
    .SginArea {
        display: flex;
        justify-content: space-around;
        height: 0;
        align-items: center;
        .Sign1,
        .Sign2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 5rem;
            justify-content: space-between;
            > span {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .DescriptionPrintArea {
        margin: 1rem 2rem 2rem 2rem;
        > div {
            > div {
                word-break: break-word;
                overflow: hidden;
                text-indent: 1rem;
                &:nth-child(1) {
                    background: var(--BMX-warning);
                    padding: 0 0.5rem;
                    > span {
                        color: var(--BMX-white);
                    }
                }
                &:nth-child(2) {
                    border: 1px solid var(--BMX-grey);
                    padding: 0.2rem;
                    height: 6rem;
                }
            }
        }
    }

    .SignArea {
        display: flex;
        position: absolute;
        right: 2rem;
        flex-direction: column;
        align-items: center;
        height: 4rem;
        justify-content: space-between;
    }
    .DatePrintArea {
        display: flex;
        position: absolute;
        left: 2rem;
        flex-direction: column;
        align-items: center;
        height: 4rem;
        justify-content: flex-end;
    }

    .Footer {
        position: absolute;
        bottom: -9rem;
        background: var(--BMX-warning);
        width: 100%;
        text-align: center;
        color: var(--BMX-white);
        font-weight: 400;
    }
}
